export const OpenPages = [
  "/robot",
  "/robot/1",
  "/robot/2",
  "/robot/3",
  "/robot/4",
  "/robot/5",
  "/robot/6",
  "/robot/7",
  "/robot/8",
  "/robot/9",
  "/robot/10",
  "/robot/11",
  "/logout",
  "/logout/advisor",
  "/logout/user",
  "/auth/signin",
  "/auth/signin/internal",
  "/auth/initSession",
  "/aml/rejected",
  "/aml/waiting-control",
  "/aml/form",
  "/createAgreement/simple-open",
];
