import { useEffect } from "react";
import { runInitialRequests } from "@portal/redux/reducers/app";
import { useAppDispatch } from "@portal/redux/store";
import { IUseInitAppProps } from "./types";
import useAdvisor from "../useAdvisor";
import { registerStockObserverForAlarms, registerStockObserverForOsloBors } from "@portal/infront/stockData";
import useAlarm from "../useAlarm";

const useInitApp = ({ userId, centerId }: IUseInitAppProps) => {
  const dispatch = useAppDispatch();
  const { isAdvisorAndNoImpersonation } = useAdvisor();
  const { data, isLoading } = useAlarm();

  useEffect(() => {
    if (userId && !isAdvisorAndNoImpersonation) {
      dispatch(runInitialRequests(centerId));
      // This is used to trigger a re-initialization of other tabs, to avoid old redux state
      window.localStorage.removeItem("re-initialized");
      window.localStorage.setItem("re-initialized", "true");
    }
  }, [dispatch, userId, isAdvisorAndNoImpersonation, centerId]);

  useEffect(() => {
    if (!isLoading) {
      registerStockObserverForAlarms(data, dispatch);
    }
  }, [dispatch, isLoading, data]);

  useEffect(() => {
    registerStockObserverForOsloBors(dispatch);
  }, [dispatch]);
};

export default useInitApp;
