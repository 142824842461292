"use client";

import PortalLayout from "@portal/components/organisms/layouts/portal";
import { AdminPages } from "@portal/helpers/adminPages";
import { usePathname } from "next/navigation";
import AdminLayout from "@portal/components/organisms/layouts/admin";
import { OpenPages } from "@portal/helpers/openPages";
import OpenLayout from "@portal/components/organisms/layouts/open";
import appInsights from "@portal/appInsights";
import { Provider } from "react-redux";
import store, { persistor } from "@portal/redux/store";
import { SessionProvider } from "next-auth/react";
import { PersistGate } from "redux-persist/integration/react";

if (typeof window !== "undefined") {
  try {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  } catch (error) {
    console.error("failed loading app insights", error);
  }
}

const RootLayout = ({ children }: Readonly<{ children: React.ReactNode }>) => {
  const pathname = usePathname();

  return (
    <SessionProvider refetchOnWindowFocus={true} refetchInterval={8 * 60}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {pathname && OpenPages.includes(pathname) && <OpenLayout>{children}</OpenLayout>}
          {pathname && AdminPages.includes(pathname) && (
            <div className={`flex w-full`}>
              <AdminLayout>{children}</AdminLayout>
            </div>
          )}
          {(!pathname || (!OpenPages.includes(pathname) && !AdminPages.includes(pathname))) && (
            <PortalLayout>{children}</PortalLayout>
          )}
        </PersistGate>
      </Provider>
    </SessionProvider>
  );
};

export default RootLayout;
