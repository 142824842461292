import useSWR, { SWRResponse } from "swr";
import commonSwrConfig from "../commonSwrConfig";
import { IAlarm } from "@portal/types/api/IAlarm";
import { IUseAlarm } from "./type";
import { ICreateAlarmRequest } from "@portal/types/api/ICreateAlarmRequest";

const useAlarm: IUseAlarm = () => {
  const fetcher = async (url: URL): Promise<IAlarm[]> => {
    const response = await fetch(url, {
      method: "GET",
      headers: [["accepts", "application/json"]],
    });

    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  };

  const { data, isLoading, error, mutate }: SWRResponse<IAlarm[], unknown> = useSWR(`/api/alarms`, fetcher, commonSwrConfig());

  const createAlarm = async (alarm: ICreateAlarmRequest): Promise<void> => {
    const response = await fetch(`/api/alarms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(alarm),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    await mutate();
  };

  const deleteAlarm = async (id: number): Promise<void> => {
    const response = await fetch(`/api/alarms?id=${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    await mutate();
  };

  return {
    data,
    createAlarm,
    deleteAlarm,
    error,
    isLoading,
  };
};

export default useAlarm;
