"use client";

import Layout from "../Layout";
import GeneralErrorPage from "@portal/components/pages/generalErrorPage";
import useCenterId from "@portal/hooks/useCenterId";
import useLoadInfrontSDK from "@portal/hooks/useLoadInfrontSDK";
import useVerifySession from "@portal/hooks/useVerifySession";
import { Mutex } from "async-mutex";
import { useSession } from "next-auth/react";
import { ErrorInfo, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { SWRConfig } from "swr";

export default function AdminLayout({ children }: Readonly<{ children: React.ReactNode }>) {
  const { status, update } = useSession();
  const centerId = useCenterId();
  const mutex = useRef(new Mutex());
  useVerifySession();
  useLoadInfrontSDK();
  return (
    <SWRConfig
      value={{
        onError: async (err: unknown) => {
          //handle expired access token (update session for trigger refresh token)
          if (status === "authenticated" && err === 401) {
            if (mutex.current.isLocked()) return;
            mutex.current.runExclusive(async () => {
              await update();
            });
          }
        },
      }}
    >
      <ErrorBoundary
        fallback={<GeneralErrorPage />}
        onError={(error: Error, info: ErrorInfo) => {
          console.error("ErrorBoundary", error, info);
        }}
      >
        <Layout centerId={centerId}>{children}</Layout>
      </ErrorBoundary>
    </SWRConfig>
  );
}
