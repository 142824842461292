import { SWRConfig } from "swr";
import GeneralAmlErrorPage from "@portal/components/pages/generalAmlErrorPage";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorInfo, useEffect, useRef } from "react";
import { Mutex } from "async-mutex";
import { useSession } from "next-auth/react";
import useCenterId from "@portal/hooks/useCenterId";
import useAdvisor from "@portal/hooks/useAdvisor";
import useInitApp from "@portal/hooks/useInitApp";
import useFeedHubStreams from "@portal/hooks/useFeedHubStreams";
import useVerifySession from "@portal/hooks/useVerifySession";
import useReInitializedTab from "@portal/hooks/useReInitializedTab";
import useVerifyAmlStatus from "@portal/hooks/useVerifyAmlStatus";
import useLoadInfrontSDK from "@portal/hooks/useLoadInfrontSDK";
import PageSpinner from "@portal/components/organisms/pageSpinner";
import GeneralErrorPage from "@portal/components/pages/generalErrorPage";
import Layout from "../Layout";
import { GetBankColorTheme } from "@portal/helpers/getBankColorTheme";

export default function PortalLayout({ children }: Readonly<{ children: React.ReactNode }>) {
  const { data: session, status, update } = useSession();
  const centerId = useCenterId();
  const { isAdvisor } = useAdvisor();

  const mutex = useRef(new Mutex());
  useInitApp({ userId: session?.user?.id, centerId: centerId ?? "" });
  useFeedHubStreams(centerId ?? "");
  useVerifySession();
  useReInitializedTab();

  useEffect(() => {
    const theme = GetBankColorTheme(centerId);
    document.body.classList.add(theme);
  });

  const { amlVerified, error: amlError } = useVerifyAmlStatus();
  // const router = useRouter();

  // TODO
  // useEffect(() => {
  //   const handleComplete = (url: string) => url === router.asPath && appInsights.trackPageView();
  //   router.events.on("routeChangeComplete", handleComplete);
  //   router.events.on("routeChangeError", handleComplete);

  //   return () => {
  //     router.events.off("routeChangeComplete", handleComplete);
  //     router.events.off("routeChangeError", handleComplete);
  //   };
  // });
  useEffect(() => {
    const handleNoScrollAtNumberFields = () => {
      document.addEventListener("wheel", () => {
        if (
          document.activeElement instanceof HTMLElement &&
          document.activeElement.tagName === "INPUT" &&
          document.activeElement.getAttribute("type") === "number"
        ) {
          document.activeElement.blur();
        }
      });
    };
    handleNoScrollAtNumberFields();
  });

  useLoadInfrontSDK();

  return (
    <SWRConfig
      value={{
        onError: async (err: unknown) => {
          //handle expired access token (update session for trigger refresh token)
          if (status === "authenticated" && err === 401) {
            if (mutex.current.isLocked()) return;
            mutex.current.runExclusive(async () => {
              await update();
            });
          }
        },
      }}
    >
      {amlError ? (
        <GeneralAmlErrorPage />
      ) : (
        <>
          {amlVerified || isAdvisor ? (
            <ErrorBoundary
              fallback={<GeneralErrorPage />}
              onError={(error: Error, info: ErrorInfo) => {
                console.error("ErrorBoundary", error, info);
              }}
            >
              <Layout centerId={centerId}>{children}</Layout>
            </ErrorBoundary>
          ) : (
            <PageSpinner />
          )}
        </>
      )}
    </SWRConfig>
  );
}
