import React from "react";
import { PortalFooter } from "../../footer";
import { ILayout } from "./types";
import { NORNE_CENTERID } from "@portal/assets/banks";
import Header from "./header";
import useCenterIdOpenPages from "@portal/hooks/useCenterIdOpenPages";

const LayoutNotVerifiedAml = ({ children }: React.PropsWithChildren<ILayout>) => {
  const centerId = useCenterIdOpenPages();
  const isNorne = centerId === NORNE_CENTERID;

  return (
    <div className={`flex flex-col w-full text-defaultText bg-greyBackground min-h-[100vh]`}>
      <div className="print:hidden bg-headerBackground text-headerMenuText">
        <div className="flex border-b">
          <Header centerId={centerId} />
        </div>
      </div>
      <div className="container mx-auto md:my-4">{children}</div>
      {isNorne && <PortalFooter centerId={centerId} />}
    </div>
  );
};

export default LayoutNotVerifiedAml;
