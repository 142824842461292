import BankLogoComponent from "@portal/components/atoms/bankLogo";
import { UserMenu } from "../../../UserMenu";

interface Props {
  centerId: string;
}

const Header = ({ centerId }: Props) => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center">
        <button onClick={() => (window.location.href = "https://norne.no")}>
          <BankLogoComponent centerId={centerId} />
        </button>
        <div className="flex items-center space-x-4">
          <UserMenu amlVerified={false} centerId={centerId} />
        </div>
      </div>
    </div>
  );
};

export default Header;
