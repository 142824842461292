import featureFlags from "@portal/helpers/featureFlag";
import { InfrontSDKSingleton } from "@portal/infront/infrontSDK";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

const useLoadInfrontSDK = () => {
  const { data: session } = useSession();
  useEffect(() => {
    if (featureFlags.isInfrontEnabled && session?.infrontAccessToken) {
      InfrontSDKSingleton.initializeSDK(session?.infrontAccessToken ?? "");
    }
  }, [session]);
};

export default useLoadInfrontSDK;
